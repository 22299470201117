/**
 * Sections
 */

import React, { forwardRef } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import ReactFullpage from '@fullpage/react-fullpage';
import css from './Sections.module.css';

// To-do: Move this section into the section builder
import { CollectionPremiereHero } from '@components';

import {
  ProductIntro,
  ProductContent,
  ProductWearing,
  ProductVideo,
  ProductFullscreen,
} from './components';

const Sections = forwardRef(({ sections, onLeave, page, showModal, toggleModal, toggleScrolling, currentSectionNumber }, ref) => {
  if (!Array.isArray(sections)) {
    return null;
  }

  // Destroy on unmount
  // useEffect(() => {
  //   return () => {
  //     const fullPageApi = get(ref, 'current.fullpageApi')
  //     if (fullPageApi) {
  //       fullPageApi.destroy();
  //     }
  //   }
  // }, []);

  const renderSections = ({ state, fullpageApi }) => {
    return (
      <ReactFullpage.Wrapper>
        {sections.map((section, index) => {
          const isActive = sections.length === 1 || index + 1 === currentSectionNumber;

            switch (section.__typename) {
              case 'WordPressAcf_hero':
                return (
                  <CollectionPremiereHero
                    key={`product-page-section-${index}`}
                    isActive={isActive}
                    title={section.title}
                    subtitle={section.subtitle}
                    videoPath={section.video_path}
                  />
                );
              case 'WordPressAcf_intro':
                return (
                  <div className="section" key={`product-page-section-${index}`}>
                    <ProductIntro 
                      isActive={isActive}
                      title={get(section, 'headline', null) || get(section, 'section_title', null)} 
                      image={section.background_image} 
                      fontSize={section.font_size}
                    />
                  </div>
                )
              case 'WordPressAcf_content':
                return (
                  <div className="section" key={`product-page-section-${index}`}>
                    <ProductContent
                      isActive={isActive}
                      id={section.id}
                      headline={section.headline}
                      headline_font_size={section.headline_font_size}
                      text={section.text}
                      type={section.type}
                      expandedText={section.expanded_text}
                      images={section.images}
                      imagesFullHeight={section.images_centered_or_full_height}
                      showModal={showModal}
                      toggleModal={toggleModal}
                    />
                  </div>
                )
              case 'WordPressAcf_wearing':
                return (
                  <div className="section" key={`product-page-section-${index}`}>
                    <ProductWearing isActive={isActive} id={section.id} images={section.images} />
                  </div>
                )
              case 'WordPressAcf_video':
                return (
                  <div className={classnames('section', css.section)} key={`product-page-section-${index}`}>
                    <ProductVideo
                      isActive={isActive}                        
                      video={section.video_background}
                      toggleModal={toggleModal}
                      toggleScrolling={toggleScrolling}
                      video_path={section.video_path}
                      video_title={section.video_title}
                      placeholder={section.placeholder}
                      showTitleAbove={section.show_title_above_video}
                    />
                  </div>
                )
              case 'WordPressAcf_fullscreen':
                return (
                  <div className="section" key={`product-page-section-${index}`}>
                    <ProductFullscreen
                      isActive={isActive}
                      text={section.text}
                      video={section.background_video}
                      video_path={section.video_path}
                      images={section.fullscreen_background_images}
                    />
                  </div>
                )
              default:
                return ''
            }
          })}
      </ReactFullpage.Wrapper>
    )
  };

  return (
    <ReactFullpage
      id="react-full-page-sections"
      licenseKey="78B68DD0-5E89429F-83882F6A-5CF1BF97"
      onLeave={onLeave}
      ref={ref}
      normalScrollElements=".overlay"
      render={renderSections}
    />
  )
})

export default Sections;
