import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Flickity from 'react-flickity-component'
import { isOffline } from '@utils';
import { cubicBezier, colors } from '../../assets/styles/constants'

class ProductContentImages extends React.Component {
  constructor(props) {
    super(props)

    this.flickityRef = React.createRef()
  }

  state = {
    disablePrevButton: true,
    disableNextButton: false,
  }

  handleOnFlickityClick = isLeft => {
    const flickityInstance = this.flickityRef.current.flkty

    flickityInstance.on('change', () => {
      if (flickityInstance.selectedIndex === 0) {
        this.setState({
          disablePrevButton: true,
          disableNextButton: false,
        })
      } else if (flickityInstance.selectedIndex === this.props.images.length - 1) {
        this.setState({
          disablePrevButton: false,
          disableNextButton: true,
        })
      } else {
        this.setState({
          disablePrevButton: false,
          disableNextButton: false,
        })
      }
    })

    if (isLeft) {
      flickityInstance.previous()
    } else {
      flickityInstance.next()
    }
  }

  render() {
    const { images, imagesFullHeight, id } = this.props
    const { disableNextButton, disablePrevButton } = this.state

    if (images === null) {
      return null
    }

    return (
      <ProductImages>
        {images.length > 1 ? (
          <FlickityWrapper>
            <FlickityArrow
              disabled={disablePrevButton}
              imagesFullHeight={imagesFullHeight}
              left={true}
              onClick={() => this.handleOnFlickityClick(true)}
            >
              <svg width="17px" height="13px" viewBox="0 0 17 13">
                <path
                  d="M11.4476024,0.854471502 C11.2160051,0.613842833 10.8305494,0.613842833 10.5908542,0.854471502 C10.3592569,1.08697082 10.3592569,1.47392774 10.5908542,1.70588511 L14.9350587,6.06700876 L0.599777707,6.06700876 C0.26560813,6.06755072 0,6.3341933 0,6.66966435 C0,7.0051354 0.26560813,7.28044928 0.599777707,7.28044928 L14.9350587,7.28044928 L10.5908542,11.6334436 C10.3592569,11.8740723 10.3592569,12.2615711 10.5908542,12.4935285 C10.8305494,12.7341572 11.2165449,12.7341572 11.4476024,12.4935285 L16.8202286,7.09997778 C17.0599238,6.86747846 17.0599238,6.48052154 16.8202286,6.24856418 L11.4476024,0.854471502 Z"
                />
              </svg>
            </FlickityArrow>
            <FlickityArrow
              disabled={disableNextButton}
              imagesFullHeight={imagesFullHeight}
              onClick={() => this.handleOnFlickityClick()}
            >
              <svg width="17px" height="13px" viewBox="0 0 17 13">
                <path
                  d="M11.4476024,0.854471502 C11.2160051,0.613842833 10.8305494,0.613842833 10.5908542,0.854471502 C10.3592569,1.08697082 10.3592569,1.47392774 10.5908542,1.70588511 L14.9350587,6.06700876 L0.599777707,6.06700876 C0.26560813,6.06755072 0,6.3341933 0,6.66966435 C0,7.0051354 0.26560813,7.28044928 0.599777707,7.28044928 L14.9350587,7.28044928 L10.5908542,11.6334436 C10.3592569,11.8740723 10.3592569,12.2615711 10.5908542,12.4935285 C10.8305494,12.7341572 11.2165449,12.7341572 11.4476024,12.4935285 L16.8202286,7.09997778 C17.0599238,6.86747846 17.0599238,6.48052154 16.8202286,6.24856418 L11.4476024,0.854471502 Z"
                />
              </svg>
            </FlickityArrow>
            {typeof window !== 'undefined' && Flickity && (
              <Flickity
                static
                ref={this.flickityRef}
                className={
                  imagesFullHeight === 'fullscreen'
                    ? 'flickity-carousel fullscreen'
                    : 'flickity-carousel'
                }
                options={{
                  prevNextButtons: false,
                }}
              >
                {images.map((image, index) => {
                  if (image === null) {
                    return null
                  }

                  const imagePath =
                    image.localFile !== null
                      ? image.localFile.childImageSharp.fixed
                      : image.source_url

                  const imageIsAvailableLocal = image.localFile !== null

                  return (
                    <div key={`product-gallery-${id}-${index}`} className="carousel-cell">
                      {imageIsAvailableLocal ? (
                        <Img
                          fixed={imagePath}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          alt=""
                          loading={isOffline ? 'eager' : 'lazy'}
                        />
                      ) : (
                        <img
                          src={imagePath}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          alt=""
                        />
                      )}
                    </div>
                  )
                })}
              </Flickity>
            )}
          </FlickityWrapper>
        ) : (
          <React.Fragment>
            {images.length === 1 && images[0].localFile ? (
              <Img
                fixed={images[0].localFile.childImageSharp.fixed}
                style={{
                  height: '100%',
                  width: '100%',
                }}
                alt=""
              />
            ) : (
              <img
                src={images[0].source_url}
                style={{
                  height: '100%',
                  width: '100%',
                }}
                alt=""
              />
            )}
          </React.Fragment>
        )}
      </ProductImages>
    )
  }
}

export default ProductContentImages

const FlickityWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  user-select: none;
  outline: none;
  position: relative;
`

const FlickityArrow = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 100%;
  border: 1px solid ${colors.accent};
  box-shadow: 0 0 1px 0px ${colors.accent} inset, 0 0 1px 0px ${colors.accent};
  cursor: pointer;
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  position: absolute;
  z-index: 10;
  top: ${props => (props.imagesFullHeight === 'fullscreen' ? '100px' : '30px;')};
  transform: ${props => (props.left ? 'rotate(180deg)' : 'rotate(0deg)')};
  right: ${props => (props.left ? '105px' : '30px')};
  opacity: ${props => (props.disabled ? '0.3' : 1)};

  svg {
    fill: ${colors.accent};
  }

  &:focus {
    outline: none;
  }
`

const ProductImages = styled.div`
  position: relative;
  width: 50vw;
  height: 100vh;
  user-select: none;

  // Gallery options
  .flickity-carousel {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    user-select: none;
    outline: none;
    position: relative;
  }
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    margin-top: 20vh;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .carousel-cell {
    width: 100%;
    height: 60vh;
  }

  .flickity-carousel.fullscreen {
    .flickity-viewport {
      margin-top: 0;
    }

    .carousel-cell {
      height: 100vh;
    }
  }

  .flickity-page-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
    display: flex;
    align-items: flex-end;

    .dot {
      display: inline-block;
      width: 60px;
      height: 44px;
      display: flex;
      align-items: center;
      margin-left: 5px;
      margin-right: 5px;
      opacity: 0.3;
      cursor: pointer;
      transition: all 0.5s ${cubicBezier};
      user-select: none;

      &:before {
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background-color: ${colors.accent};
      }

      &.is-selected {
        opacity: 1;
        &:before {
          height: 4px;
        }
      }
    }
  }
`
