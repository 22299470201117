import React from 'react'
import get from 'lodash/get';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { isOffline } from '@utils';
import Flickity from 'react-flickity-component';
import { cubicBezier } from '../../assets/styles/constants'

class ProductFullscreenImages extends React.Component {
  constructor(props) {
    super(props)

    this.flickityRef = React.createRef()
  }

  state = {
    animate: false,
    disablePrevButton: true,
    disableNextButton: false,
  }

  componentDidMount() {
    this.setState({
      animate: true, 
    }, () => {
      setTimeout(() => {
        this.setState({
          animate: false
        })
      }, 1000) 
    });
  }

  handleOnFlickityClick = isLeft => {
    const flickityInstance = this.flickityRef.current.flkty

    flickityInstance.on('change', () => {
      if (flickityInstance.selectedIndex === 0) {
        this.setState({
          disablePrevButton: true,
          disableNextButton: false,
        })
      } else if (flickityInstance.selectedIndex === this.props.images.length - 1) {
        this.setState({
          disablePrevButton: false,
          disableNextButton: true,
        })
      } else {
        this.setState({
          disablePrevButton: false,
          disableNextButton: false,
        })
      }
    })

    if (isLeft) {
      flickityInstance.previous()
    } else {
      flickityInstance.next()
    }
  }

  render() {
    const { images, id } = this.props
    const { animate, disableNextButton, disablePrevButton } = this.state

    if (!images || !images.length) {
      return null
    }

    // Only one image – just show the image without slider functionality
    if (images.length === 1) {
      const image = images[0];

      return (
        <ProductImages>
          <BackgroundImage
            critical={isOffline}
            fluid={get(image, 'image.localFile.childImageSharp.fluid')}
            style={{
              height: '100%',
              width: '100%',
              backgroundSize: get(image, 'adjustment', 'cover'),
            }}
            />
        </ProductImages>
      );
    }
    return (
        <ProductImages>
          <FlickityWrapper>
            <FlickityArrowsWrap className="presentation-mode-control">
              <FlickityArrow
                disabled={disablePrevButton}
                left={true}
                onClick={() => this.handleOnFlickityClick(true)}
              >
                <svg viewBox="0 0 17 13">
                  <path
                    d="M11.4476024,0.854471502 C11.2160051,0.613842833 10.8305494,0.613842833 10.5908542,0.854471502 C10.3592569,1.08697082 10.3592569,1.47392774 10.5908542,1.70588511 L14.9350587,6.06700876 L0.599777707,6.06700876 C0.26560813,6.06755072 0,6.3341933 0,6.66966435 C0,7.0051354 0.26560813,7.28044928 0.599777707,7.28044928 L14.9350587,7.28044928 L10.5908542,11.6334436 C10.3592569,11.8740723 10.3592569,12.2615711 10.5908542,12.4935285 C10.8305494,12.7341572 11.2165449,12.7341572 11.4476024,12.4935285 L16.8202286,7.09997778 C17.0599238,6.86747846 17.0599238,6.48052154 16.8202286,6.24856418 L11.4476024,0.854471502 Z"
                  />
                </svg>
              </FlickityArrow>
              <FlickityArrow
                disabled={disableNextButton}
                onClick={() => this.handleOnFlickityClick()}
                animate={animate}
              >
                <svg viewBox="0 0 17 13">
                  <path
                    d="M11.4476024,0.854471502 C11.2160051,0.613842833 10.8305494,0.613842833 10.5908542,0.854471502 C10.3592569,1.08697082 10.3592569,1.47392774 10.5908542,1.70588511 L14.9350587,6.06700876 L0.599777707,6.06700876 C0.26560813,6.06755072 0,6.3341933 0,6.66966435 C0,7.0051354 0.26560813,7.28044928 0.599777707,7.28044928 L14.9350587,7.28044928 L10.5908542,11.6334436 C10.3592569,11.8740723 10.3592569,12.2615711 10.5908542,12.4935285 C10.8305494,12.7341572 11.2165449,12.7341572 11.4476024,12.4935285 L16.8202286,7.09997778 C17.0599238,6.86747846 17.0599238,6.48052154 16.8202286,6.24856418 L11.4476024,0.854471502 Z"
                  />
                </svg>
              </FlickityArrow>
            </FlickityArrowsWrap>
            {typeof window !== 'undefined' && Flickity && (
              <Flickity
                static
                ref={this.flickityRef}
                className="flickity-carousel"
                options={{
                  prevNextButtons: false,
                  setGallerySize: false,
                }}
              >
                {images.map((data, index) => {
                  const image = get(data, 'image.localFile.childImageSharp.fluid', null);
                  const backgroundSize = get(data, 'adjustment', 'cover');

                  if (!image) {
                    return null;
                  }

                  return (
                    <div key={`product-gallery-${id}-${index}`} className="carousel-cell">
                      <BackgroundImage
                        fluid={image}
                        fadeIn="soft"
                        style={{
                          width: '100%',
                          height: '100%',
                          backgroundSize,
                        }}
                      />
                    </div>
                  )
                })}
              </Flickity>
            )}
          </FlickityWrapper>
      </ProductImages>
    )
  }
}

export default ProductFullscreenImages

const FlickityWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  user-select: none;
  outline: none;
  position: relative;
  height: 100%;
`

const FlickityArrow = styled.button`
  width: 4rem;
  height: 4rem;
  background: transparent;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ${({ animate }) => animate ? '1s' : '0.35s'};
  user-select: none;
  opacity: ${({ animate, disabled}) => {
    if (animate) {
      return 1;
    }
    if (disabled) {
      return `0 !important`;
    }

    return 0.3;
  }};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background-color: #FFF;
  transform: scale(${({ animate }) => {
    if (animate) {
      return 1.34;
    }
    return 1.03;
  }});

  svg {
    fill: #000;
    height: 1.35rem;
    transform: ${props => (props.left ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
    opacity: 1;
    outline: none;
  }

  &:active {
    transform: scale(1.3);
  }
`

const FlickityArrowsWrap = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  right: 3rem;
  left: 3rem;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
`


const ProductImages = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  user-select: none;

  // Gallery options
  .flickity-carousel {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    user-select: none;
    outline: none;
    position: relative;
    height: 100%;
  }
  .flickity-viewport {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .flickity-slider {
    height: 100%;
  }

  .carousel-cell {
    width: 100%;
    height: 100%;
  }

  .flickity-page-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 5rem;
    bottom: 0;
    right: 5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .dot {
      display: inline-block;
      position: relative;
      flex: 1;
      max-width: 4rem;
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0;
      opacity: 0.3;
      cursor: pointer;
      transition: all 0.5s ${cubicBezier};
      padding: 3rem 0;

      &:before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 2rem;
        height: 6px;
        width: 100%;
        background-color: #FFF;
        transition: height 0.2s;
      }

      &.is-selected, &:hover {
        opacity: 1;
        &:before {
          height: 10px;
        }
      }
    }
  }
`
