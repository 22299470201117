import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { isOffline } from '@utils';
import { cubicBezier, MQ } from '../../assets/styles/constants'

class ProductWearing extends React.PureComponent {
  state = {
    selectedWearingItem: null,
    wearingOverlayOpen: false,
  }

  handleOnClickWearingItem = index => {
    if (this.state.wearingOverlayOpen) {
      return null
    }
    this.setState({ selectedWearingItem: index, wearingOverlayOpen: true })
  }

  handleOnClickWearingOverlay = () => {
    this.setState({ selectedWearingItem: null, wearingOverlayOpen: false })
  }

  render() {
    const { images, id } = this.props
    const { selectedWearingItem } = this.state

    if (images === null) {
      return null
    }

    return (
      <ProductWearingWrapper>
        {images.map((item, index) => {
          const { headline, text, image } = item

          if (image === null) {
            return null
          }

          const imagePath =
            image.localFile !== null ? image.localFile.childImageSharp.fixed : image.source_url

          const imageIsAvailableLocal = image.localFile !== null

          return (
            <WearingItem
              onClick={() => this.handleOnClickWearingItem(index)}
              key={`wearing-item-${id}-${index}`}
              className="wearing-item"
            >
              {imageIsAvailableLocal ? (
                <Img
                  loading={isOffline ? 'eager' : 'lazy'}
                  fixed={imagePath}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                  }}
                  alt={headline}
                />
              ) : (
                <img
                  src={imagePath}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                  }}
                  alt={headline}
                />
              )}
              <Teaser className="wearing-teaser">{headline}</Teaser>
              <WearingOverlay selectedWearingItem={selectedWearingItem} index={index}>
                <Content className="wearing-overlay-content">
                  <ContentInner className="wearing-overlay-content-inner">
                    <CloseButton onClick={() => this.handleOnClickWearingOverlay()} />
                    <Headline>{headline}</Headline>
                    <ContentText dangerouslySetInnerHTML={{ __html: text }} />
                  </ContentInner>
                </Content>
                <Media className="wearing-overlay-media">
                  {imageIsAvailableLocal ? (
                    <Img
                      fixed={imagePath}
                      style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                      }}
                      alt={headline}
                    />
                  ) : (
                    <img
                      src={imagePath}
                      style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                      }}
                      alt={headline}
                    />
                  )}
                </Media>
              </WearingOverlay>
            </WearingItem>
          )
        })}
      </ProductWearingWrapper>
    )
  }
}

export default ProductWearing

const ProductWearingWrapper = styled.div`
  display: flex;
  padding-top: 10vh;
  height: 90vh;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;

  @media screen and (${MQ.lg}) {
    &:hover {
      .wearing-item {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`

const WearingItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  transition: all 0.5s ${cubicBezier};
  opacity: 1;

  @media screen and (${MQ.lg}) {
    &:hover {
      flex: 2;
      .wearing-teaser {
        transition: all 0.5s ${cubicBezier} 0.3s;
        opacity: 1;
        bottom: 140px;
      }
    }
  }
`

const Teaser = styled.span`
  font-size: 3rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  display: block;
  position: absolute;
  bottom: 0;
  opacity: 0;
  z-index: 2;
`

const WearingOverlay = styled.div`
  position: fixed;
  left: 0;
  height: 80vh;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ${cubicBezier};
  background: rgba(0, 0, 0, 0.95);
  opacity: ${props => (props.index === props.selectedWearingItem ? 1 : 0)};
  pointer-events: ${props => (props.index === props.selectedWearingItem ? 'auto' : 'none')};
  -webkit-backface-visibility: hidden;
  user-select: none;

  ${props =>
    props.index === props.selectedWearingItem &&
    `
        width: 100vw;
        .wearing-overlay-content {
          width: 50vw;
          transition: all 1s ${cubicBezier} 1s;
        }
        .wearing-overlay-content-inner {
          opacity: 1;
          transition: all 0.5s ${cubicBezier} 1.5s;
        }
        .wearing-overlay-media {
          opacity: 1;
        }
      `}
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 0;
  height: 100%;
  position: relative;
  transition: all 1s ${cubicBezier};
`

const ContentInner = styled.div`
  transition: all 0.5s ${cubicBezier};
  opacity: 0;
  max-width: 80%;
`

const Headline = styled.span`
  display: block;
  font-size: 3rem;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
`

const ContentText = styled.div`
  font-size: 1.3rem;
`

const Media = styled.div`
  position: relative;
  width: 50vw;
  height: 100%;
  user-select: none;
  transition: all 1s ${cubicBezier};
  opacity: 0;
`

const CloseButton = styled.button`
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0px #fff inset, 0 0 1px 0px #fff;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  transform: scale(1);
  position: absolute;
  top: 150px;
  right: 50px;
  opacity: 0.5;

  &:before,
  &:after {
    content: '';
    background: #fff;
    height: 1px;
    width: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(135deg);
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`

export const sectionsWearing = graphql`
  fragment SectionWearing on WordPressAcf_wearing {
    ... on WordPressAcf_wearing {
      primary
      section_title
      id
      images {
        headline
        text
        image {
          source_url
          localFile {
            childImageSharp {
              fixed(width: 1400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`