import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';

import {
  DefaultMainMenu,
  MainMenu,
  ProgressMenu,
  SectionMenu,
  ScrollIndicator,
} from '@components';

import Sections from '../../sections';

class PageCategoryProduct extends React.Component {
  constructor(props) {
    super(props)

    this.reactFullPageRef = React.createRef()
  }
  state = {
    activePage:
      this.props.location.state && this.props.location.state.clickedSectionId
        ? this.props.location.state.clickedSectionId
        : 1,
    showScrollIndicator: true,
    showModal: false,
  }

  componentDidMount() {
    const { activePage } = this.state;

    if (activePage && activePage > 1) {
      this.onItemClick(activePage);
    }
  }

  componentWillUnmount() {
    const fullPageApi = get(this.reactFullPageRef, 'current.fullpageApi');
    if (fullPageApi) {
      fullPageApi.destroy('all');
    }
  }

  handleReactFullPageAfterLoad = ({ origin, destination, direction }) => {
    this.setState({
      activePage: destination.index + 1,
      showScrollIndicator: false,
    });
  }

  toggleScrolling = toggle => {
    this.reactFullPageRef.current.fullpageApi.setAllowScrolling(toggle)
  }

  toggleModal = toggle => {
    this.setState({ showModal: toggle })
  }

  handleOnSectionClick = clickedSection => {
    this.onItemClick(clickedSection);
  }

  onItemClick = (number) => {
    window.location.hash = number;
    this.reactFullPageRef.current.fullpageApi.moveTo(number)
  };

  getSections = () => {
    return get(this.props.data, 'product.acf.sections_page', []) || [];
  }
  
  /**
   * For presentation mode only (collection highlights)
   */
  getMenuItems = () => this.getSections()
  .map((section, index) => Object.assign(section, { number: index + 1 }))
  .filter((section) => section.primary)
  .map((section, index) => (
    {
      label: section.section_title,
      onClick: () => this.onItemClick(section.number),
      image: null,
    }
  ))

  render() {
    const product = this.props.data.product;
    const isPresentationMode = get(product, 'acf.mode') === 'highlights';
    const lengthOfSections = product.acf.sections_page ? product.acf.sections_page.length : 0
    const edges = get(this.props.data, 'products.edges', []) || [];
    const products = edges.filter(
      productsElement => get(productsElement, 'node.parent_element.id') === get(product, 'parent_element.id'),
    );

    return (
      <React.Fragment>
        { isPresentationMode ? (
          <MainMenu
            shortcut={{
              to: '/collection',
              label: 'Switch to Full Collection'
            }}
            items={this.getMenuItems()}
          />
        ) : <DefaultMainMenu />}
        <ProgressMenu
          itemType={isPresentationMode ? 'number' : 'dot'}
          selected={this.state.activePage}
          items={lengthOfSections}
          onItemClick={this.onItemClick}
          presentationMode={isPresentationMode}
        />
        {!isPresentationMode && (
          <SectionMenu
            selectedSection={this.state.activePage}
            currentPageId={product.id}
            products={products}
            onSectionClick={this.handleOnSectionClick}
          />
        )}
        {this.state.showModal && <style>{`.fullpage-wrapper {z-index: 1100;}`}</style>}
        <Sections 
          ref={this.reactFullPageRef}
          page={product}
          sections={this.getSections()}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          toggleScrolling={this.toggleScrolling}
          currentSectionNumber={this.state.activePage}
          onLeave={(origin, destination, direction) => {
            this.handleReactFullPageAfterLoad({ origin, destination, direction })
          }}
        />
        {lengthOfSections > 1 && (
          <ScrollIndicator 
            label=""
            showScrollIndicator={this.state.showScrollIndicator}
          />
        )}
      </React.Fragment>
    )
  }
}

export default PageCategoryProduct

export const pageQuery = graphql`
  query($id: String!) {
    products: allWordpressPage(
      filter: { template: { eq: "page-category-product.php" } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          slug
          title
          id
          parent_element {
            id
            slug
          }
          acf {
            cp_hero_title
            cp_hero_subtitle
            cp_hero_video_path
            sections_page {
              __typename
              ... on WordPressAcf_intro {
                primary
                section_title
                id
              }
              ... on WordPressAcf_content {
                primary
                section_title
                id
              }
              ... on WordPressAcf_wearing {
                primary
                section_title
                id
              }
              ... on WordPressAcf_video {
                primary
                section_title
                id
              }
              ... on WordPressAcf_fullscreen {
                primary
                section_title
                id
              }
              ... on WordPressAcf_hero {
                primary
                section_title
                id
              }
            }
          }
        }
      }
    }
    product: wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      id
      slug
      parent_element {
        id
      }
      acf {
        mode
        sections_page {
          __typename
          ...SectionContent
          ...SectionFullscreen
          ...SectionIntro
          ...SectionProductVideo
          ...SectionWearing
          ...SectionHero
        }
      }
    }
  }
`
